import React, { FunctionComponent } from "react";
import ProfileIcon from "web-common/src/components/Header/profileIcon";
import { InternalLink } from "web-common/src/types/SanityTypes";
import { Link } from "web-common/src/components/Link";

interface ProfileToggleInterface {
  data: {
    sanityNewsletterSignUp?: InternalLink;
  };
}

const ProfileToggle: FunctionComponent<ProfileToggleInterface> = ({ data }) => {
  return (
    <>
      {data.sanityNewsletterSignUp && (
        <Link
          to={data.sanityNewsletterSignUp.slug.current}
          _id={data.sanityNewsletterSignUp._id}
          data-analytics-event57=""
        >
          <ProfileIcon />
        </Link>
      )}
    </>
  );
};
export default ProfileToggle;
